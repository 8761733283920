:root {
  /* official branding
   * https://docs.google.com/presentation/d/10h9PAM_0bVTNEpjHloAyvlzDMlKuG-xMrqGmFzGM3_M/edit#slide=id.g26c090d9188_1_31
   */
  --aa-violet: #8800ff; /* electric violet */
  --aa-pink: #ff216e; /* hot pink */
  --aa-black: #000;
  --aa-yellow: #e3fc26; /* chartreuse yellow */
  --aa-beige: #ededd9; /* white rock */
  --aa-blue: #0600ff;
  --aa-turquoise: #00e1ff; /* turquoise blue */

  /* modified colors */
  --aa-light-black: #1c1c1c;
  --aa-light-beige: #f5f6eaff;

  --bs-body-font-family: "Helvetica Neue", "Helvetica", "Arial";
}


.bg-dark {
  --bs-dark-rgb: 28, 28, 28; /* Light black */
  --bs-navbar-brand-color: var(--aa-light-beige);
  --bs-navbar-brand-hover-color: var(--aa-yellow);
}

.bg-secondary {
  --bs-secondary-rgb: 28,28,28; /* Light black */
  --bs-bg-opacity: 0.90;
  --aa-active-nav-rgb: 255,255,255;
  --aa-active-nav-opacity: 0.20;
}

.nav {
    --bs-nav-link-color: var(--aa-light-beige);
    --bs-nav-link-hover-color: var(--aa-yellow);
}

.nav-tabs {
  --bs-nav-link-color: black;
  --bs-nav-link-hover-color: var(--aa-blue);
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#layout-loading, #layout-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#header .avatar {
  height: 28px;
  cursor: pointer;
}

#header .avatar img {
  height: 100%;
  border-radius: 100px;
}

.avatar-popover {
  padding: 1em;
}
